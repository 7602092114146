<template>
  <van-popup
    v-model="visible"
    :close-on-click-overlay="false"
    position="bottom"
    :style="popupStyle"
    get-container="body"
    round
    v-bind="$attrs"
    :class="['question-popup-batch']"
    @open="onOpen"
    @close="onClose"
    @click-overlay="onClose"
    v-on="$listeners"
  >
    <h3 class="popup-title">
      <i class="iconclose iconfont iconfont-danchuangguanbi" @click="onClose" />
      <span>问题记录详情</span>
    </h3>
    <div :class="[`question-popup-batch__container`]">
      <van-form
        label-width="100px"
        :show-error-message="false"
        :class="['van-form__text-color', editable && 'has-submit-btn']"
        colon
        input-align="right"
        @submit="onConfirm"
      >
        <div v-for="(item, idx) in info.questionList" :key="item.uid || idx">
          <div class="sub-title">
            <span>{{ `${idx + 1}、${info.itemName}` }}</span>
            <i
              v-if="editable"
              class="iconfont iconfont-shanchu2"
              @click="onDelete(idx)"
            />
          </div>
          <van-field
            :rules="[{ required: !!editable, message: '请选择问题涉及部门' }]"
            :value="item.deptInvolveName"
            :clickable="editable"
            label="问题涉及部门"
            name="deptInvolve"
            placeholder="请选择"
            readonly
            :required="editable"
            @click="onDepartmentClick(item, idx)"
          />
          <van-field
            v-model="item.content"
            label="问题描述"
            maxlength="500"
            name="content"
            :placeholder="!editable ? '--' : '请输入问题描述'"
            rows="2"
            :autosize="!editable"
            :readonly="!editable"
            :show-word-limit="editable"
            type="textarea"
          />
          <van-field label="问题照片" name="uploader">
            <template #input>
              <image-uploader
                :ref="`uploader-${idx}`"
                v-model="item.files"
                :max-count="3"
                :disabled="!editable"
                accept="image/*,video/*"
                @change="val => onImageChange(val, idx)"
              />
            </template>
          </van-field>
        </div>
        <div v-if="editable" class="confirm-button">
          <van-button block round type="default" @click="onClose"
            >取 消</van-button
          >
          <van-button
            :disabled="loading.status"
            :loading="loading.status"
            :loading-text="loading.text"
            block
            native-type="submit"
            round
            type="info"
            >确 认</van-button
          >
        </div>
      </van-form>
    </div>
    <cascade-department
      v-model="department"
      :visible="departmentVisible"
      @close="departmentVisible = false"
      @confirm="onDepartmentChange"
    />
  </van-popup>
</template>

<script>
import { getFileUrlForFileSystem } from "@/utils/file";
import { loading } from "@/utils/constant";
import ImageUploader from "@/views/trouble/trouble-fix/components/ImageUploader";
import CascadeDepartment from "@/components/CascadeDepartment";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "BatchQuestionPopup",
  components: { CascadeDepartment, ImageUploader },
  props: {
    popupStyle: {
      type: Object,
      default: () => ({ height: "80vh" })
    },
    visible: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: true
    },
    questionInfo: {
      type: Object,
      default: () => ({
        itemName: "",
        questionList: []
      })
    }
  },
  data() {
    return {
      loading: { ...loading },
      departmentVisible: false,
      department: "",
      departmentName: "",
      deptIndex: -1,
      info: {}
    };
  },
  methods: {
    onDepartmentClick(item, idx) {
      if (!this.editable) return;
      this.department = item.deptInvolve;
      this.departmentName = item.deptInvolveName;
      this.deptIndex = idx;
      this.departmentVisible = true;
    },
    onDepartmentChange(o) {
      this.info.questionList[this.deptIndex].deptInvolve = o.value;
      this.info.questionList[this.deptIndex].deptInvolveName = o.label;
    },
    async onOpen() {
      this.info = cloneDeep(this.questionInfo);
      console.log("onOpen -> questionInfo", this.info);
      await this.$nextTick();
      this.info.questionList.forEach((item, idx) => {
        const fileList =
          item?.files?.map(x => {
            x.url = getFileUrlForFileSystem(x.id);
            return x;
          }) || [];
        console.log(fileList, "fileList");
        this.$refs[`uploader-${idx}`][0].setInnerValue(fileList);
      });
    },
    onDelete(idx) {
      this.info.questionList.splice(idx, 1);
    },
    onClose() {
      this.$emit("close");
      this.info.questionList.forEach((item, idx) => {
        this.$refs[`uploader-${idx}`][0].setInnerValue([]);
      });
      this.$nextTick(() => {
        this.info = {};
      });
    },
    onConfirm() {
      this.$emit("confirm", cloneDeep(this.info.questionList));
      this.$emit("close");
    },
    onImageChange(val, idx) {
      this.$set(this.info.questionList[idx], "files", val);
    }
  }
};
</script>

<style lang="scss">
.question-popup-batch {
  box-sizing: border-box;
  overflow: hidden;

  .has-submit-btn {
    padding-bottom: 80px;
  }

  &__container {
    overflow: auto;
    height: calc(100% - 40px);
    .sub-title {
      display: flex;
      justify-content: space-between;
      color: #2e2e4d;
      line-height: 22px;
      font-size: 16px;
      padding: 0 16px;
      margin-bottom: 10px;
      i {
        font-size: 22px;
        color: #99a3af;
      }
    }
  }

  .popup-title {
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    padding: 16px;
    font-weight: 500;
    color: #2e2e4d;

    i {
      float: left;
      color: #9496a3;
    }
  }

  .confirm-button {
    position: absolute;
    bottom: 0;
    width: 100vw;
    padding: 0 20px 16px;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    .van-button--default {
      margin-right: 12px;
    }
  }
  .image-uploader {
    flex-direction: row-reverse;
  }
}
</style>
