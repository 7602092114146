import { deepFreeze } from "@/utils";

export const inspectClassTypes = deepFreeze([
  { label: "现场检查", value: "0" },
  { label: "作业票检查", value: "1" }
]);

export const isExistProblem = deepFreeze([
  { label: "是", value: "0" },
  { label: "否", value: "1" }
]);

export const inspectStatusTypes = deepFreeze([
  { label: "未检查", value: "0" },
  { label: "已检查", value: "1" }
]);

export const workSmallColorMap = deepFreeze({
  0: "#3679bf",
  1: "#2da6e9",
  2: "#59cbc0",
  3: "#51c8ed",
  4: "#fac967",
  5: "#f69b64",
  6: "#f176a6",
  7: "#bb61a6",
  8: "#0fb8c5"
});
