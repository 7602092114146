var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"work-inspect-add"},[_c('van-nav-bar',{attrs:{"left-arrow":"","title":"作业监督检查记录"},on:{"click-left":function($event){return _vm.$router.back()}}}),_c('van-form',{class:['van-form__text-color', 'wrapper-height__not-feature-btn'],attrs:{"show-error-message":false,"label-width":"30vw","colon":""}},[_c('van-field',{attrs:{"border":false,"readonly":"","value":_vm.info.checkTypeName,"label":"检查方式"}}),_c('van-field',{attrs:{"border":false,"readonly":"","value":_vm.info.deptCheckName,"label":"检查部门"}}),_c('van-field',{attrs:{"border":false,"readonly":"","value":_vm.info.checkerName,"label":"检查人"}}),_c('van-field',{attrs:{"border":false,"readonly":"","value":_vm.info.ctime,"label":"检查时间"}}),_c('div',{staticClass:"line-block"}),(_vm.isListPage)?[_c('van-field',{staticClass:"link",attrs:{"border":false,"value":_vm.info.ticketName,"label":"作业名称/内容","readonly":""},on:{"click":_vm.toWorkPage}}),_c('van-field',{attrs:{"border":false,"value":_vm.info.facilatyName,"label":"作业区域","readonly":""}}),_c('van-field',{attrs:{"border":false,"value":_vm.info.workDeptName,"label":"所属部门","readonly":""}}),_c('div',{staticClass:"line-block"})]:_vm._e(),_c('h3',[_c('span',{staticClass:"color",style:({
          'background-color': _vm.workSmallColorMap[_vm.info.specialJobType || 0]
        })}),_vm._v(" "+_vm._s(_vm.info.workTypeName)+" "),_c('span',{staticClass:"problem",style:({
          'background-color':
            _vm.questionTagMap[_vm.info.questionStatus] &&
            _vm.questionTagMap[_vm.info.questionStatus].color
        })},[_vm._v(_vm._s(_vm.questionTagMap[_vm.info.questionStatus] && _vm.questionTagMap[_vm.info.questionStatus].text))])]),_c('ul',{staticClass:"inspect-project"},[_c('li',{staticClass:"inspect-project__title"},[_c('span',{staticClass:"project-item"},[_vm._v("检查项目")]),_c('span',{staticClass:"question-len"},[_vm._v("问题数量")])]),_vm._l((_vm.info.checkRecordItemList),function(project){return _c('li',{key:project.itemId,staticClass:"inspect-project__item"},[_c('span',{staticClass:"project-item"},[_vm._v(_vm._s(project.itemName))]),_c('span',{staticClass:"question-len",style:({
            color: _vm.hasQuestion(project) ? '#1676FF' : ''
          }),on:{"click":function($event){return _vm.onShowQuestion(project)}}},[_vm._v(_vm._s(_vm.hasQuestion(project) ? project.questionList.length : ""))])])})],2)],2),_c('batch-question-popup',{attrs:{"editable":false,"visible":_vm.batchDialogInfo.visible,"question-info":_vm.batchDialogInfo.info},on:{"close":function($event){_vm.batchDialogInfo.visible = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }