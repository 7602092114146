<template>
  <div class="work-inspect-add">
    <van-nav-bar
      left-arrow
      title="作业监督检查记录"
      @click-left="$router.back()"
    />
    <van-form
      :show-error-message="false"
      label-width="30vw"
      colon
      :class="['van-form__text-color', 'wrapper-height__not-feature-btn']"
    >
      <van-field
        :border="false"
        readonly
        :value="info.checkTypeName"
        label="检查方式"
      />
      <van-field
        :border="false"
        readonly
        :value="info.deptCheckName"
        label="检查部门"
      />
      <van-field
        :border="false"
        readonly
        :value="info.checkerName"
        label="检查人"
      />
      <van-field
        :border="false"
        readonly
        :value="info.ctime"
        label="检查时间"
      />
      <div class="line-block" />
      <template v-if="isListPage">
        <van-field
          :border="false"
          :value="info.ticketName"
          label="作业名称/内容"
          readonly
          class="link"
          @click="toWorkPage"
        />
        <van-field
          :border="false"
          :value="info.facilatyName"
          label="作业区域"
          readonly
        />
        <van-field
          :border="false"
          :value="info.workDeptName"
          label="所属部门"
          readonly
        />
        <div class="line-block" />
      </template>
      <h3>
        <span
          class="color"
          :style="{
            'background-color': workSmallColorMap[info.specialJobType || 0]
          }"
        />
        {{ info.workTypeName }}
        <span
          class="problem"
          :style="{
            'background-color':
              questionTagMap[info.questionStatus] &&
              questionTagMap[info.questionStatus].color
          }"
          >{{
            questionTagMap[info.questionStatus] &&
              questionTagMap[info.questionStatus].text
          }}</span
        >
      </h3>
      <ul class="inspect-project">
        <li class="inspect-project__title">
          <span class="project-item">检查项目</span>
          <span class="question-len">问题数量</span>
        </li>
        <li
          v-for="project in info.checkRecordItemList"
          :key="project.itemId"
          class="inspect-project__item"
        >
          <span class="project-item">{{ project.itemName }}</span>
          <span
            class="question-len"
            :style="{
              color: hasQuestion(project) ? '#1676FF' : ''
            }"
            @click="onShowQuestion(project)"
            >{{ hasQuestion(project) ? project.questionList.length : "" }}</span
          >
        </li>
      </ul>
    </van-form>
    <batch-question-popup
      :editable="false"
      :visible="batchDialogInfo.visible"
      :question-info="batchDialogInfo.info"
      @close="batchDialogInfo.visible = false"
    />
  </div>
</template>

<script>
import {
  inspectClassTypes,
  isExistProblem,
  workSmallColorMap
} from "@/views/WorkInspect/utils/constant";
import { getInfoWorkInspectRecordById } from "@/api/psm/workInspect";
import "./styles/details.scss";
import BatchQuestionPopup from "./components/BatchQuestionPopup";

export default {
  name: "WorkInspectRecordDetail",
  components: { BatchQuestionPopup },
  props: ["id"],
  data() {
    return {
      isListPage: false,
      inspectClassTypes,
      isExistProblem,
      workSmallColorMap,
      questionTagMap: {
        0: { text: "存在问题", color: "#fb7171" },
        1: { text: "不存在问题", color: "#3683f4" }
      },
      info: {
        checkRecordItemList: []
      },
      batchDialogInfo: {
        visible: false,
        list: [],
        info: {}
      }
    };
  },
  created() {
    this.isListPage = !!this.$route.query?.isListPage;
    this.getInfo();
  },
  methods: {
    toWorkPage() {
      this.$router.push({
        name: "worktickeDetail",
        params: { id: this.info.ticketId }
      });
    },
    hasQuestion(project) {
      return !!(project.questionList && project.questionList.length);
    },
    async getInfo() {
      try {
        this.info = await getInfoWorkInspectRecordById(this.id);
      } catch (e) {
        console.log(e);
      }
    },
    onShowQuestion(data) {
      if (!this.hasQuestion(data)) return false;
      console.log(data, "Dialog");
      this.batchDialogInfo.info = { ...data };
      this.batchDialogInfo.list = [...data.questionList];
      this.batchDialogInfo.visible = true;
    }
  }
};
</script>

<style scoped></style>
